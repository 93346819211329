const CheckDisplayModule = (module, environment, partnerUser) => {

    const {info} = environment.data
    const partnerTypeIsFE = environment.partnerTypeIsFE

    if (!environment.data.info.location_type_id) {

        switch (module) {
            case 'transactions':
                return !((info.hide_transactions && info.lite_environment) || partnerUser.data.hide_transactions)
            case 'stock':
            case 'scanItems':
            case 'itemsLate':
            case 'itemsInService':
                return (!((info.hide_stock && info.lite_environment) || partnerUser.data.hide_stock));
            case 'rentalMutationsDashboard':
                return (!((info.hide_rental_mutations && info.lite_environment) || partnerUser.data.hide_rental_mutations))
            case 'rentalMutations':
                return !((info.hide_rental_mutations && info.lite_environment) || partnerUser.data.hide_rental_mutations);
            case 'availability':
                return !((info.hide_availability && info.lite_environment) || partnerUser.data.hide_availability);
            case('rentalCreate'):
                return !((info.hide_rental_create && info.lite_environment) || partnerUser.data.hide_rental_create);
            case 'financial':
                return !((info.hide_financial && info.lite_environment) || partnerUser.data.hide_financial);
            case 'openingTimes':
                return !!partnerUser.data.can_edit_opening_times;
            default:
                return true

        }
    } else return false

}

export default CheckDisplayModule
