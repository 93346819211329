import React from 'react'
import {DateRangePicker} from "rsuite";
import FormatDate from "../../../common/helpers/FormatDate";
import DashboardAction from "../../../stores/dashboard/DashboardAction";
import DateRangeData from "../../../common/constants/DateRangeData";

const {ranges, locale} = DateRangeData()

const TableFilter = (props) => {

    const display = props.display
    const deliveryFilter = props.deliveryFilter

    // disable buttons for FE partners
    const partnerTypeIsFE = props.environment.partnerTypeIsFE
    if (partnerTypeIsFE) {
        for (let filterItem of display.options) {
            switch (filterItem.type) {
                case 'return':
                    filterItem.show = true
                    break
                case 'registration':
                    filterItem.show = true
                    break
                case 'items-return':
                    filterItem.show = true
                    break
                case 'late':
                    filterItem.show = true
                    break
            }
        }
    }

    if (props.list.data && props.period.start && props.period.end) {
        return (
            <div className={'c-table-filter'}>

                <ul>
                    {display.options.filter(filterItem => filterItem.show).map((filterItem) => {
                        const className = filterItem.type === display.active ? ' is-active' : ''
                        return (
                            <li
                                className={className}
                                key={'filter-item-' + filterItem.type}
                                onClick={() => {
                                    changeFilter(filterItem.type)
                                }}>
                                {filterItem.label}
                            </li>
                        )
                    })}
                </ul>

                <div className={'u-float-right'}>

                    <DateRangePicker
                        isoWeek={true}
                        defaultValue={[props.period.start, props.period.end]}
                        value={[props.period.start, props.period.end]}
                        locale={locale}
                        ranges={ranges}
                        onChange={(data) => changeDashboardDate(data)}
                        renderValue={(value) => {
                            return FormatDate(value[0]) + ' ~ ' + FormatDate(value[1]);
                        }}
                    />

                    <select onChange={(e) => {

                        if (display.active === 'items' || display.active === 'items-return') {
                            props.dispatch(DashboardAction.requestItemsInPeriod(false, e.target.value))
                        } else {
                            props.dispatch(DashboardAction.requestRentalsInPeriod(false, e.target.value))
                        }

                    }}>
                        {deliveryFilter.options.map((filterItem) => {
                            const selected = filterItem.type === deliveryFilter.active
                            return (
                                <option
                                    defaultValue={selected}
                                    key={'filter-sub-item-' + filterItem.type}
                                    value={filterItem.type}>
                                    {filterItem.label}
                                </option>
                            )
                        })}
                    </select>

                </div>

            </div>
        )
    } else return null

    function changeFilter(type) {

        if (type === 'items' || type === 'items-return') {
            props.dispatch(DashboardAction.requestItemsInPeriod(type))
        } else {
            props.dispatch(DashboardAction.requestRentalsInPeriod(type))
        }

    }

    function changeDashboardDate(data) {

        if (display.active === 'items' || display.active === 'items-return') {
            props.dispatch(DashboardAction.requestItemsInPeriod(false, false, false, false, data))
        } else {
            props.dispatch(DashboardAction.requestRentalsInPeriod(false, false, false, false, data))
        }

    }


}


export default TableFilter